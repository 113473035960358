import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Error from "components/Error";
import { useCompanyContext } from "context/CompanyContext/CompanyContext";
import {
    SectionTitle,
    SectionContent,
    SectionFooter,
    SectionFooterButton,
} from "components/Page/Section";
import SuccessAlert from "components/Alerts";
import Modal from "components/Modal";
import { put } from "utils/api";
import FieldsContainer from "components/Page/Containers/FieldsContainer";
import ButtonGroup from "components/MaterialUi/ButtonGroup";
import TextField from "components/MaterialUi/TextField";
import Label from "components/MaterialUi/Label";

TextField.displayName = "AiSectionTextField";

export const AiSection = ({ companyId, ai }) => {
    const history = useHistory();
    const { reloadCompanies } = useCompanyContext();
    const [ask, setAsk] = useState(ai.ask || false);
    const [workmateLicenseAllowance, setWorkmateLicenseAllowance] = useState(ai.workmate ? ai.workmate.licenseAllowance : 0);
    const [workmateOverage, setWorkmateOverage] = useState(ai.workmate ? ai.workmate.overage : 0);
    const [agentsInteractionAllowance, setAgentsInteractionAllowance] = useState(ai.agents ? ai.agents.interactionAllowance : 0);
    const [agentsOverage, setAgentsOverage] = useState(ai.agents ? ai.agents.overage : 0);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        setError(null);
    }, [history.location.key]);

    const [formErrors, setFormErrors] = useState({});
    const [modalOpen, toggleModalOpen] = useState(false);

    const handleAskChange = (val) => {
        if (val !== ask) {
            setAsk(val);
            setUnsavedChanges(true);
        }
    };

    const handleWorkmateLicenseAllowanceChange = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0;
        }

        setWorkmateLicenseAllowance(e.target.value);
        setUnsavedChanges(true);
    };

    const handleWorkmateOverageChange = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0;
        }

        setWorkmateOverage(e.target.value);
        setUnsavedChanges(true);
    };

    const handleAgentsInteractionAllowanceChange = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0;
        }

        setAgentsInteractionAllowance(e.target.value);
        setUnsavedChanges(true);
    };

    const handleAgentsOverageChange = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0;
        }

        setAgentsOverage(e.target.value);
        setUnsavedChanges(true);
    };

    const handleSaveSuccess = async () => {
        setSaveSuccessful(true);
        setTimeout(() => setSaveSuccessful(false), 5000);
        setSaving(false);
        setUnsavedChanges(false);
        setFormErrors({});
        await reloadCompanies();
    };

    const enableSaveButton = () => {
        if (saving) {
            return false;
        }

        if (!unsavedChanges) {
            return false;
        }

        return true;
    };

    const submitChanges = async () => {
        toggleModalOpen(false);
        setSaving(true);

        try {
            await put(`ai/${companyId}`, {
                ask,
                workmateLicenseAllowance,
                workmateOverage,
                agentsInteractionAllowance,
                agentsOverage,
            });
            handleSaveSuccess();
        } catch (err) {
            const { status, errors, userMessage } = err;
            if (status === 400) {
                setFormErrors(errors);
            } else if (userMessage) {
                setError(userMessage);
            } else {
                setError(err.message);
            }
            setSaving(false);
        }
    };

    if (error) {
        return <Error error={error} />;
    }

    return (
        <>
            <Modal
                open={modalOpen}
                text="Are you sure you want to save?"
                onConfirm={submitChanges}
                onClose={() => toggleModalOpen(false)}
            />
            <SectionTitle title="Ai" />
            <SectionContent>
                <FieldsContainer>
                    <Label>Ask</Label>
                    <ButtonGroup datagnatta="enabled">
                        <Button
                            aria-label="enabled"
                            value="enabled"
                            color={ask ? "secondary" : "default"}
                            variant="contained"
                            size="small"
                            onClick={() => handleAskChange(true)}
                        >
                            enabled
                        </Button>
                        <Button
                            aria-label="disabled"
                            value="disabled"
                            color={!ask ? "secondary" : "default"}
                            variant="contained"
                            size="small"
                            onClick={() => handleAskChange(false)}
                        >
                            disabled
                        </Button>
                    </ButtonGroup>
                    <TextField
                        label="Workmate - Licenses"
                        size="small"
                        error={!!formErrors.WorkmateLicenseAllowance}
                        defaultValue={workmateLicenseAllowance}
                        color="secondary"
                        type="number"
                        onChange={(e) => handleWorkmateLicenseAllowanceChange(e)}
                        helperText={formErrors.WorkmateLicenseAllowance ? formErrors.WorkmateLicenseAllowance[0] : null}
                        id="workmate-license"
                    />
                    <TextField
                        label="Workmate - Overage Licenses"
                        size="small"
                        error={!!formErrors.WoorkmateOverage}
                        defaultValue={workmateOverage}
                        color="secondary"
                        type="number"
                        onChange={(e) => handleWorkmateOverageChange(e)}
                        helperText={formErrors.WoorkmateOverage ? formErrors.WoorkmateOverage[0] : null}
                        id="workmate-license"
                    />
                    <TextField
                        label="Agents - Interactions"
                        size="small"
                        error={!!formErrors.AgentsInteractionAllowance}
                        defaultValue={agentsInteractionAllowance}
                        color="secondary"
                        type="number"
                        onChange={(e) => handleAgentsInteractionAllowanceChange(e)}
                        helperText={formErrors.AgentsInteractionAllowance ? formErrors.AgentsInteractionAllowance[0] : null}
                        id="agents-interactions"
                    />
                    <TextField
                        label="Agents - Overage Interactions"
                        size="small"
                        error={!!formErrors.AgentsOverage}
                        defaultValue={agentsOverage}
                        color="secondary"
                        type="number"
                        onChange={(e) => handleAgentsOverageChange(e)}
                        helperText={formErrors.AgentsOverage ? formErrors.AgentsOverage[0] : null}
                        id="agents-interactions"
                    />
                </FieldsContainer>
                <SuccessAlert message="Ai details saved" show={saveSuccessful} />
            </SectionContent>
            <SectionFooter>
                <SectionFooterButton
                    onClick={() => toggleModalOpen(true)}
                    disabled={!enableSaveButton()}
                    buttonText="SAVE"
                />
            </SectionFooter>
        </>
    );
};

AiSection.propTypes = {
    ai: PropTypes.shape({
        ask: PropTypes.bool,
        workmate: PropTypes.shape({
            licenseAllowance: PropTypes.number,
            overage: PropTypes.number,
        }),
        agents: PropTypes.shape({
            interactionAllowance: PropTypes.number,
            overage: PropTypes.number,
        }),
    }),
    companyId: PropTypes.string,
};

AiSection.defaultProps = {
    ai: {
        ask: false,
        workmate: {
            licenseAllowance: 0,
            overage: 0,
        },
        agents: {
            interactionAllowance: 0,
            overage: 0,
        },
    },
};

export default AiSection;
